import { version } from '$app/environment';
import { PUBLIC_NODE_ENV } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';
import { inDevelopment, inProduction, inStaging } from './env';

let replaysSessionSampleRate = 1;
if (inProduction()) replaysSessionSampleRate = 0.2;

const integrations: any[] = [new Sentry.BrowserTracing()];
// For now, in development, we don't want to replay sessions (there is a small issue with hot reload, you can activate it if you want)
if (!inDevelopment()) {
	integrations.push(
		Sentry.replayIntegration({
			maskAllText: true,
			blockAllMedia: true
		})
	);
}

Sentry.init({
	dsn: 'https://9f43a1333bb510587f7044d1c5895fed@o4507185626742784.ingest.de.sentry.io/4507185634738256',

	integrations,
	environment: PUBLIC_NODE_ENV,
	release: 'fixee-web@' + version,

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: inDevelopment() || inStaging() ? 1 : 0.2,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0
});
